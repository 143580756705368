import { findAll, find } from "../utils/dom";


function chanceText(text) {
    const img = text.querySelector('img')
    console.log(text.innerText)

    if (text.innerText.toUpperCase().includes("ENGLISH")) {
        text.innerHTML = `
            ${img.outerHTML}
            EN
        `
    }
    else if (text.innerText.toUpperCase().includes("PORTUGUÊS")) {
        text.innerHTML = `
            ${img.outerHTML}
            BR
        `
    }
}

const DebugTranslate = () => {
    const Texts = findAll('.gt_container--hich1m .gt_switcher .gt_selected a');
    const TextsContainer = findAll('.gt_container--hich1m .gt_switcher .gt_option a');
    if (Texts) {
        Texts.forEach((text) => {
            chanceText(text)
        })
    }
    if (TextsContainer) {
        TextsContainer.forEach((text) => {
            chanceText(text)
        })
    }
}

export default DebugTranslate;