import Swiper from "swiper"
import { find } from "utils/dom"

const Quem_Somos = () => {
    const QuemSomos = find("[js-page='quem_somos']")
    if (!QuemSomos) return


    const ClassSwiperService = "Swiper-Nosso-Time";
    const SwiperService = new Swiper(`.${ClassSwiperService}`, {
        slidesPerView: 1,
        spaceBetween: 32,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: `[js-button-next="${ClassSwiperService}"]`,
            prevEl: `[js-button-prev="${ClassSwiperService}"]`,
        },
        pagination: {
            el: `[js-controll="${ClassSwiperService}"]`,
            clickable: true,
        },
        breakpoints: {
            600: {
                slidesPerView: 2.4,
            },
            1200: {
                slidesPerView: 3,
            }

        }
    })



}

export default Quem_Somos;