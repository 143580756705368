import Swiper from "swiper"
import { find } from "utils/dom"

const Single_Servico = () => {
    const SingleServico = find("[js-page='single_services']")
    if (!SingleServico) return

    const ClassListCard = "Swiper-List-Card";
    const ListCard = new Swiper(`.${ClassListCard}`, {
        slidesPerView: 1.2,
        spaceBetween: 32,
        breakpoints: {
            600: {
                slidesPerView: 2.4,
            },
            1200: {
                slidesPerView: 3,
            }

        }
        /* navigation: {
             nextEl: `[js-button-next="${ClassListCard}"]`,
             prevEl: `[js-button-prev="${ClassListCard}"]`,
         },
         pagination: {
             el: `[js-controll="${ClassListCard}"]`,
             clickable: true,
         },*/
    })
}

export default Single_Servico;