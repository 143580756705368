import { find } from "utils/dom"

export default function () {
    const PostDetail = find("[js-page=postdetail]")
    if (!PostDetail) return

    const ButtonShare = find("[js-button-share]");
    const SiteUrl = window.location.href;


    if (ButtonShare) {
        ButtonShare.addEventListener("click", (e) => {
            navigator.clipboard.writeText(SiteUrl);
            let pageLeng = document.querySelector('[lang]').getAttribute('lang');

            if (pageLeng == 'en') {
                e.target.innerHTML = "Link copied";
            }
            else {
                e.target.innerHTML = "Link copiado";
            }
            e.target.removeAttribute('secondary');
            e.target.setAttribute('primary', '')
        })
    }

}