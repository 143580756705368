import { find } from "../utils/dom";

const Menu = () => {
    const menu_content = find('[js-menu]');
    const menu_button = find('[js-button]');
    const menu_label = find('[js-label-menu]');

    if (!menu_content || !menu_button) return

    menu_button.addEventListener("click", (event) => {
        if (event.target.classList.contains('active')) {
            event.target.classList.remove('active');
            menu_content.classList.remove('active');
        }
        else {
            event.target.classList.add('active');
            menu_content.classList.add('active');
        }

    })

    document.addEventListener("scroll", (event) => {
        const EixoY = window.scrollY;
        if (EixoY > 780) {
            menu_label.classList.add("w-reduz")
        }
        else {
            menu_label.classList.remove("w-reduz")
        }
    })
}

export default Menu;