import Swiper from "swiper"
import { find } from "utils/dom"

const Single_Produto = () => {
    const SingleProduto = find("[js-page='single_produto']")
    if (!SingleProduto) return


    const ClassSwiperThumbs = "thumbs-slider";
    const SlideThumbs = new Swiper(`.${ClassSwiperThumbs}`, {
        slidesPerView: 3,
        spaceBetween: 8,
        autoHeight: true,
        direction: "horizontal",
        breakpoints: {
            600: {
                direction: "vertical",
            }
        }

    })

    const ClassSwiperProduto = "Swiper-Produto";
    const SlideProduto = new Swiper(`.${ClassSwiperProduto}`, {
        slidesPerView: 1,
        effect: "fade",
        thumbs: {
            swiper: SlideThumbs,
        },

    })



}

export default Single_Produto;