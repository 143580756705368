import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageHome from './pages/Home.js'
import PageLogin from "./pages/Login";
import PageQuemSomos from './pages/QuemSomos';
import PageSingleServico from './pages/SingleServico';
import PageSingleProduto from './pages/SingleProduto';
import PagePostDetail from './pages/PostDetail';
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from 'swiper/element/bundle';
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";

import DebugTranslate from './modules/debugTranslate';

import Contact from "./modules/Contact";

import Menu from './modules/menu';
import EditorJS from './modules/EditorJS';



// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()

// Pages
PageHome()
PageQuemSomos()
PageSingleServico()
PageSingleProduto()
PagePostDetail()
PageLogin()?.init()

// utils logs
logs()
Contact()

document.addEventListener("DOMContentLoaded", () => {
    DebugTranslate()
    Menu()
    EditorJS()
})


