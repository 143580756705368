import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return



	/** Swiper Banner */
	const ClassSwiperBanner = "Swiper-Banner";
	const SwiperBanner = new Swiper(`.${ClassSwiperBanner}`, {
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 900,
		loop: true,
		effect: "fade",
		autoplay: {
			delay: 6000,
		},
		pagination: {
			el: `[js-pagination="${ClassSwiperBanner}"]`,
			type: "fraction",
		},
		navigation: {
			nextEl: `[js-slide-next="${ClassSwiperBanner}"]`,
			prevEl: `[js-slide-prev="${ClassSwiperBanner}"]`,
		}

	})

	const ClassSwiperService = "Swiper-Servicos"
	const SwiperService = new Swiper(`.${ClassSwiperService}`, {
		slidesPerView: 1,
		spaceBetween: 8,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: `[js-button-next="${ClassSwiperService}"]`,
			prevEl: `[js-button-prev="${ClassSwiperService}"]`,
		},
		pagination: {
			el: `[js-controll="${ClassSwiperService}"]`,
			clickable: true,
		},
		breakpoints: {
			600: {
				slidesPerView: 2.4,
			},
			1200: {
				slidesPerView: 4,
			}

		}
	})


	const ClassSwiperProduto = "Swiper-Produto";
	const SwiperProduto = new Swiper(`.${ClassSwiperProduto}`, {
		slidesPerView: 1,
		spaceBetween: 32,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: `[js-button-next="${ClassSwiperProduto}"]`,
			prevEl: `[js-button-prev="${ClassSwiperProduto}"]`,
		},
		pagination: {
			el: `[js-controll="${ClassSwiperProduto}"]`,
			clickable: true,
		},
		breakpoints: {
			600: {
				slidesPerView: 2.4,
			},
			1200: {
				slidesPerView: 3,
			}

		}
	})

}